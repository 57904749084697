import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/storage';
import 'firebase/auth';

const config = {
  apiKey: "AIzaSyAlMm2VBrMEZbdSwpeLLsrGsgCaCtq8ZbU",
  authDomain: "file-manager-f2baa.firebaseapp.com",
  databaseURL: "https://file-manager-f2baa.firebaseio.com",
  projectId: "file-manager-f2baa",
  storageBucket: "file-manager-f2baa.appspot.com",
  messagingSenderId: "1061036306133",
  appId: "1:1061036306133:web:ee4b9966328cd454"
};
firebase.initializeApp(config);
export default firebase;